@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1330px;
  }
}

header {
  background: #fff;
}
.logo h3 {
  color: #333333;
  font-weight: 700;
}
.logo h3 span {
  color: #f18247;
}
.logo,
.logo:hover {
  text-decoration: none;
}
.page-height {
  min-height: calc(100vh - 170px);
}

.result-item h2 {
  font-size: 1.2em;
  font-weight: 600;
}
.result-item h3 {
  font-size: 1.2em;
  font-weight: 700;
}
.result-item .img-fluid {
  width: 100%;
  object-fit: cover;
  height: 100%;
  max-height: 206px;
  min-height: 206px;
}
.search-results-air .result-item .img-fluid {
  width: 100%;
  object-fit: cover;
  height: auto;
  max-height: none;
  min-height: auto;
}
.search-results-air .result-item ul li:last-child {
  border-bottom: 0px none !important;
}
.search-results-transfers .result-item .img-fluid {
  object-fit: contain;
  padding: 0px 16px;
  max-height: 158px;
  min-height: 158px;
}

/* Colors */
.gray-color {
  color: rgba(51, 51, 51, 0.65);
}
.btn-primary {
  background: rgb(241, 130, 71);
  border-color: rgb(241, 130, 71);
}
.btn-primary:hover {
  background: rgb(200, 81, 17);
  border-color: rgb(200, 81, 17);
}
.btn-primary:focus {
  background: rgb(241, 130, 71);
  border-color: rgb(241, 130, 71);
}
.star-rating i {
  color: #f18247;
  margin-right: 5px;
}
.text-primary {
  color: rgb(241, 130, 71) !important;
}
.nav-tabs .nav-link.active {
  border-color: rgb(241, 130, 71);
  background: rgb(241, 130, 71);
  color: #fff;
}
.pagination .page-link {
  color: #f18247;
}
.btn-outline-primary {
  color: rgb(241, 130, 71);
  border-color: rgb(241, 130, 71);
}
.btn-outline-primary:hover {
  background: rgb(241, 130, 71);
  border-color: rgb(241, 130, 71);
}
.custom-control-input:checked ~ .custom-control-label::before {
  background: rgb(241, 130, 71);
  border-color: rgb(241, 130, 71);
}

/* TC Customizations */
body .noUi-handle {
  background: #f26722;
  border: solid 3px #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px #e6e6e6;
}
body .noUi-handle:before,
body .noUi-handle:after {
  height: 0px;
  width: 0px;
}
body .noUi-target {
  background: rgba(221, 221, 221, 0.38823529411764707);
  border: 0px none;
  border-radius: 0px;
  margin-bottom: 0px !important;
  height: 8px;
  margin-top: 1px !important;
}
body .noUi-horizontal .noUi-handle {
  width: 4px;
  height: 19.2px;
  left: 0px;
  top: -5px;
  right: 0px !important;
  background: #f18247;
  border: 0px none;
  box-shadow: none;
  border-radius: 0px;
}
body .noUi-connects {
  border-radius: 0px;
}
body .noUi-connect {
  background: #f18247;
  border: 0px none;
  box-shadow: none;
  border-radius: 0px;
}

.filter-area h3 {
  font-size: 1.1em;
  text-transform: capitalize;
  font-weight: 600;
}

.filter-price {
  order: 1;
}
.filter-starrating {
  order: 2;
}
.filter-name {
  order: 3;
}
.filter-category {
  order: 4;
}
.filter-currency {
  order: 5;
}
.filter-nearby {
  order: 6;
}
.filter-amenity {
  order: 7;
}
.filter-mealtype {
  order: 8;
}

/* .filter-nearby ul,
.filter-amenity ul,
.filter-mealtype ul {
  max-height: 240px;
  overflow: hidden;
} */

.filter-area .filter-hide {
  max-height: 240px;
  overflow: hidden;
}

.search-results .result-item:last-child > div.border-bottom {
  border-bottom: 0px none !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.search-results {
  background: #fff;
}

.result-sorting ul li button.active.asc i {
  transform: rotate(180deg);
}

.filter-area {
  font-size: 0.9em;
}

.search-tabs .active button {
  background: #fff;
}

.btn:focus,
.btn:active,
.btn.active {
  outline: none !important;
  box-shadow: none;
}

.auto-complete {
  z-index: 100;
  position: absolute;
  background: #fff;
  width: calc((100% - 30px));
  font-size: 0.9em;
}
.auto-complete li:hover {
  background: #36c;
  color: #fff;
  cursor: pointer;
}

/* Detail Photo Gallery Classes */
.carousel li.slide {
  height: 460px;
}
.carousel .slide img {
  width: 100% !important;
  height: 100% !important;
  vertical-align: middle;
  border: 0;
}
.carousel .slide div {
  width: auto !important;
  height: 100% !important;
  vertical-align: middle;
  border: 0;
}
.carousel .thumbs {
  padding: 0px;
  margin: 0px;
  height: 80px;
}
.carousel .thumb {
  height: 100%;
  margin: 0px 0px;
  margin-right: 4px !important;
  border: 0px solid #fff !important;
}
.carousel.carousel-slider {
  margin-bottom: 4px !important;
}
.carousel img {
  object-fit: cover;
  -webkit-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  -moz-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  -o-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  -ms-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
}
body .carousel .thumbs-wrapper {
  margin: 0px;
}
.carousel .thumb img {
  vertical-align: middle;
  height: 100%;
  -webkit-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  -moz-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  -o-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  -ms-transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
  transition: 0.6s cubic-bezier(0.665, 0, 0.43, 1) all;
}
body .carousel .thumb.selected,
.carousel .thumb:hover {
  border: 0px solid #333 !important;
}
.carousel .thumb:hover img,
.carousel .slide:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.carousel.carousel-slider .control-arrow .control-prev {
  left: -50px;
}
.carousel.carousel-slider .control-arrow .control-next {
  right: -50px;
  text-align: right;
}
.flexslider:hover .carousel.carousel-slider .control-arrow .control-prev {
  opacity: 0.7;
  left: 10px;
}
.flexslider:hover .carousel.carousel-slider .control-arrow .control-prev:hover {
  opacity: 1;
}
.flexslider:hover .carousel.carousel-slider .control-arrow .control-next {
  opacity: 0.7;
  right: 10px;
}
.flexslider:hover .flex-direction-nav .control-next:hover {
  opacity: 1;
}

/* Details Page */
.overview-content {
  overflow: hidden;
  max-height: 98px;
}

.amenities ul li::before,
.policies ul li::before {
  content: "";
  width: 6px;
  height: 6px;
  border: 1px solid #f18247;
  background: #f18247;
  line-height: 35px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  margin-right: 14px;
}

.cart .img-fluid {
  width: 100%;
  object-fit: cover;
  height: 100%;
  max-height: 232px;
}
.cart .col-lg-2 > .img-fluid {
  width: 100%;
  object-fit: cover;
  height: auto;
  max-height: none;
  min-height: auto;
}
.cart .img-fluid-transfers {
  object-fit: contain;
  background: #f8f9fa;
  padding: 16px;
}

/* Login Section */
.loginbox {
  position: absolute;
  top: 48px;
  right: 0px;
  z-index: 10000;
  width: 320px;
}
.loginbox:before {
  position: absolute;
  right: 104px;
  top: -9px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #dee2e6;
  content: "";
}

.login-menu {
  position: absolute;
  top: 48px;
  right: 0px;
  z-index: 10000;
  width: 100%;
}
.login-menu:before {
  position: absolute;
  right: 104px;
  top: -9px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #dee2e6;
  content: "";
}

.lang-list {
  position: absolute;
  top: 43px;
  right: 0px;
  z-index: 10000;
  width: auto;
}

.lang-list li {
  font-size: 0.875rem;
  cursor: pointer;
}
.lang-list li:last-child {
  padding-bottom: 0% !important;
  margin-bottom: 0% !important;
  border-bottom: none !important;
}
.lang-list:before {
  position: absolute;
  right: 24px;
  top: -10px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #dee2e6;
  content: "";
}

.Cart-list {
  top: 43px;
  right: 241px;
  z-index: 10000;
  width: auto;
}

.hight-z-index {
  z-index: 99;
}
.Cart-list li {
  font-size: 0.875rem;
  cursor: pointer;
}
.Cart-list li:last-child {
  padding-bottom: 0% !important;
  margin-bottom: 0% !important;
  border-bottom: none !important;
}
.Cart-list:before {
  position: absolute;
  right: 20px;
  bottom: 40px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid #c7c9cc;
  content: "";
}

.map-pin {
  margin-bottom: -15px;
}

.map-pin-animation {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  z-index: 10000 !important;
  cursor: pointer;
}

@keyframes example {
  0% {
    top: 0px;
  }
  50% {
    top: -20px;
  }
  100% {
    top: 0px;
  }
}
.shadow-center {
  box-shadow: 0 0rem 2rem rgba(0, 0, 0, 0.175) !important;
}

/* Profile Page */
.profile-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
/* Profile Page */
.upload-doc {
  width: 85px;
  height: 75px;
  border-radius: 5%;
  display: inline-block;
}

/* Search Widget */
.search-widget .fa-calendar {
  position: absolute;
  bottom: 10px;
  right: 24px;
}
.react-bootstrap-daterangepicker-container {
  display: inherit !important;
}
.ac_loading {
  background-image: url("../images/ac_loading.gif");
  background-position: right center;
  background-repeat: no-repeat;
}
.room-selector {
  line-height: 20px;
}
.room-selection {
  position: absolute;
  z-index: 100;
  top: 92px;
}
.room-selection:before {
  position: absolute;
  left: 10%;
  top: -9px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #dee2e6;
  content: "";
}
.mod-search .form-group > label {
  display: none;
}
.mod-search .form-group {
  margin-bottom: 0px;
}
.mod-search .react-bootstrap-daterangepicker-container .col-lg-6 {
  padding-right: 0px;
}
.mod-search .room-selection {
  top: 54px;
}

.mod-search .BE-Search-Radio {
  margin-top: 0px;
  width: 100%;
}
.mod-search .BE-Search-Radio ul li label {
  line-height: 24px;
}
.mod-search .BE-Search-Radio ul {
  width: 100%;
}
.mod-search .BE-Search-Radio ul li {
  width: 50%;
}
.mod-search .BE-Search-Radio .multicity li {
  width: 33% !important;
}
.mod-search .BE-Search-Radio ul li label {
  width: 100%;
  text-align: center;
}
.mod-search
  .search-widget-transfers
  .react-bootstrap-daterangepicker-container
  .form-group.col-lg-6
  svg,
.mod-search
  .search-widget-air
  .react-bootstrap-daterangepicker-container
  .form-group.col-lg-6
  svg {
  right: 12px !important;
}
.mod-search
  .search-widget-transfers
  .react-bootstrap-daterangepicker-container
  .form-group.col-lg-12
  svg,
.mod-search
  .search-widget-air
  .react-bootstrap-daterangepicker-container
  .form-group.col-lg-12
  svg {
  right: 12px !important;
}
.mod-search
  .search-widget-transfers
  .react-bootstrap-daterangepicker-container
  .form-group.col-lg-12 {
  padding-right: 0px;
}
.mod-search
  .search-widget-air
  .react-bootstrap-daterangepicker-container
  .form-group.col-lg-12 {
  padding-right: 0px;
}

/* Loading Results Animation */
.results-loading h2,
.results-loading h3,
.results-loading .badge {
  background: rgba(0, 0, 0, 0.05);
  border: 0px none;
}
.results-loading .results-loading-img {
  height: 196px;
  background: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.results-loading h2,
.results-loading h3,
.results-loading .badge {
  background: linear-gradient(273deg, #f1f1f1 36%, #ffffff 48%, #f1f1f1 36%);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

.cart-loading h5,
.cart-loading b,
.cart-loading .btn {
  background: rgba(0, 0, 0, 0.05);
  border: 0px none;
}
.cart-loading-img {
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.3;
}
.cart-loading h5,
.cart-loading b,
.cart-loading .btn {
  background: linear-gradient(273deg, #f1f1f1 36%, #ffffff 48%, #f1f1f1 36%);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

.details-loading h3,
.details-loading h6,
.details-loading .btn {
  background: rgba(0, 0, 0, 0.05);
  border: 0px none;
}

.details-loading h3,
.details-loading h6,
.details-loading .btn {
  background: linear-gradient(273deg, #f1f1f1 36%, #ffffff 48%, #f1f1f1 36%);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

.details-loading-img {
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.3;
  height: 320px;
  display: block;
}

.bookings-loading b {
  background: rgba(0, 0, 0, 0.05);
  border: 0px none;
}

.bookings-loading b {
  background: linear-gradient(273deg, #f1f1f1 36%, #ffffff 48%, #f1f1f1 36%);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.invalid {
  border: red solid 1px;
}

/* Date Picker Icon Postion */
.booking-form .fa-calendar {
  position: absolute;
  top: 44px;
  right: 24px;
}

/* Contact Number Flag Dropdown */
.intl-tel-input {
  position: absolute;
  width: 48px;
  height: 38px;
  font-size: 0.8em;
}
.intl-tel-input input {
  display: none;
}
.form-group.mobilenumber input,
.form-group.secondarynumber input {
  padding-left: 52px;
}

/* Custom Background */
.title-bg {
  /* background: #043c5d; */
  background: rgb(67, 76, 91);
}

.f30 {
  font-size: 1.5rem;
}

.booking-steps {
  font-size: 0.95rem;
}
.booking-steps .step {
  position: relative;
  z-index: 1;
}
.booking-steps .step span {
  width: 30px;
  height: 30px;
  background: #fff;
  display: block;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.booking-steps .step:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 2px;
  background: #fff;
  left: 50%;
  top: 14px;
}
.booking-steps .step:last-child:after {
  display: none;
}

.booking-steps .step.active span {
  background: rgb(241, 130, 71);
  color: #fff;
}
.booking-steps .step.active label {
  color: rgb(241, 130, 71) !important;
}
/* .booking-steps .step.active:after {
  background: rgb(241, 130, 71);
} */
.booking-steps .step.completed:after {
  background: rgb(241, 130, 71);
}

.bookings .card ul:last-child {
  border-bottom: 0px none !important;
  margin-bottom: 0px !important;
}

.details-photoslider .swiper-slide {
  height: 356px;
}
.details-photoslider .swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.badge-aminities,
.badge-boardtype {
  margin-right: 5px;
}

.room-show-details {
  margin-top: 10px;
}

.room-child-paxicon {
  font-size: 0.75em;
}

.room-pax-icons i {
  margin-right: 3px;
}

span.badge.badge-secondary.badge-aminities,
span.badge.badge-secondary.badge-boardtype {
  font-size: 13px;
}

.pricebreackup-badge {
  font-size: 15px;
  letter-spacing: 1px;
}

.ribbons {
  background: rgb(241, 130, 71);
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 4px 6px;
  color: #fff;
  font-size: 0.9em;
}

.notification-box {
  background: #000;
  padding: 0px 8px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  border-radius: 4px 4px 0px 0px;
}

.quick-book .amenities {
  font-size: 0.9em;
  padding-left: 8px;
}
.quick-book .amenities ul {
  margin-bottom: 0px !important;
}
.quick-book .overview {
  margin-top: 8px !important;
}
.quick-book .rooms .img-fluid {
  max-height: none;
  min-height: auto;
}
.quick-book h4 {
  font-size: 1.3em;
  display: none !important;
}
.quick-book .inclusionsexclusions h4 {
  font-size: 1em;
  display: block !important;
}
.quick-book .amenities-icons {
  margin-top: -30px;
}
.quick-book .amenities-icons span {
  display: none;
}
.quick-book .amenities-icons span.badge {
  border: 0px none !important;
  background: none !important;
  text-align: left;
  margin: 8px 0px 0px 0px !important;
  display: block;
  width: 33%;
  float: left;
}
.quick-book .amenities-icons span.badge img {
  height: 24px !important;
  width: 24px !important;
}
.quick-book .amenities-icons span.badge small {
  font-size: 1.2em;
  padding: 0px 0px 0px 8px;
}
.quick-book .amenities-icons span.badge div {
  display: none;
}
.quick-book .overview-content {
  overflow: hidden;
  max-height: 126px;
}

/* Radio Switch */
.BE-Search-Radio {
  float: left;
  clear: both;
  margin-top: -3px;
}
.BE-Search-Radio ul {
  float: left;
  list-style: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}
.BE-Search-Radio ul li {
  float: left;
  margin: 0px;
  padding: 0px;
  position: relative;
}
.BE-Search-Radio ul li input[type="radio"] {
  float: left;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 26px !important;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.BE-Search-Radio ul li label {
  background: #f1f1f1;
  color: #212529;
  padding: 6px 12px 8px 12px;
  font-weight: 400;
  font-size: 14px;
  float: left;
  margin: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  border-radius: 0px;
  line-height: 16px;
  width: auto;
  position: relative;
  cursor: pointer;
  font-family: inherit;
}
.BE-Search-Radio ul li input:checked + label {
  background: rgb(241, 130, 71);
  color: #fff;
}
.BE-Search-Radio ul li:first-child label {
  border-radius: 4px 0px 0px 4px;
}
.BE-Search-Radio ul li:last-child label {
  border-radius: 0px 4px 4px 0px;
}

.custom-class-daterangepicker-oneway .drp-calendar.right {
  display: none;
}
.custom-class-daterangepicker-roundtrip .drp-calendar.right {
  display: block !important;
}

/* Airline Stops */
.stops-cont {
  width: 100%;
  height: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(241, 130, 71);
  position: relative;
}
.stops-cont span {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid rgb(241, 130, 71);
  box-sizing: border-box;
  border-radius: 50%;
}
.flight-dtl-info-pad {
  margin-left: 90px;
}
.btn-facebook {
  background: #507cc0;
  border-color: #507cc0;
  color: #dee2e6;
}
.btn-facebook:hover {
  background: #0b66ee;
  border-color: #0b66ee;
  color: #dee2e6;
}
.btn-google {
  background: #dd4b39;
  border-color: #dd4b39;
  color: #dee2e6;
}

/* Call Center Classes */
.call-center select {
  min-width: 280px !important;
}
.call-center input {
  min-width: 420px !important;
}
.call-center-selection input {
  min-width: 280px !important;
}
.call-center .reset-btn {
  right: 68px !important;
}

@media (max-width: 575px) {
  .f30 {
    font-size: 1.5rem;
  }
  .search-tabs {
    display: flex;
    overflow-x: auto;
    margin: 0px 0px -1px 0px !important;
  }
  .search-tabs li .btn {
    font-size: 0px;
    border-right: 1px solid #dee2e6;
    padding: 16px !important;
  }
  .search-tabs li .btn svg.mr-2 {
    margin: 0px !important;
  }
  .search-widget .btn.btn-primary {
    width: 100%;
  }
  .search-widget h2 {
    font-size: 1.5rem;
  }
  .result-views,
  .wishlist {
    display: none !important;
  }
  .result-responsive-btn {
    display: block !important;
    margin-top: -24px;
  }
  .result-sorting b {
    display: none !important;
  }
  .result-sorting {
    float: left;
    margin-top: -24px;
    margin-left: -8px;
    width: 100%;
    text-align: left;
    overflow-x: auto;
  }
  .result-sorting ul {
    white-space: nowrap;
  }
  .result-sorting ul li button.btn {
    font-size: 12px;
    padding: 4px 4px 2px 8px;
  }
  .result-filter-area {
    display: none;
  }
  .result-filter-area-show {
    display: block !important;
  }
  .responsive-results-btns {
    display: block !important;
    position: fixed;
    bottom: 8px;
    left: 8px;
    z-index: 10000;
  }
  .mod-search-area {
    display: none;
  }
  .mod-search-area-show {
    display: block;
  }
  .mod-search .search-widget {
    padding-bottom: 0px !important;
  }
  .search-widget .row > div {
    margin-bottom: 16px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .search-widget .row > div .react-bootstrap-daterangepicker-container {
    padding-right: 16px;
  }
  .search-widget
    .row
    > div
    .react-bootstrap-daterangepicker-container
    .row
    > div {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0px;
  }

  .search-results-hotel {
    text-align: center;
  }
  .search-results-hotel .bg-light div {
    width: 50%;
    float: left;
  }
  .search-results-hotel .bg-light > div .btn {
    width: 100%;
  }
  .search-results-hotel .bg-light > .btn.btn-outline-primary {
    width: calc(50% - 16px);
    margin-top: 4px !important;
    margin-left: 16px;
  }
}

/* Booking Form Customizatoion */
.booking-form .passportExpirationDate label {
  white-space: nowrap;
}

.suggestion-item--active {
  font-size: 1rem;
}
.suggestion-item {
  font-size: 1rem;
}

.swap-icon {
  top: 80px;
  right: 16px;
  position: absolute;
  z-index: 1;
}
.swap-icon-modify {
  top: 8px;
  right: -20px;
  position: absolute;
  z-index: 1;
  background: rgb(67, 76, 91) !important;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

/* Page Classes */
.dashboard-boxes div {
  border-bottom: 1px solid #e3e5e9;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: relative;
  width: 46%;
  margin: 16px 2%;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
}
.dashboard-boxes div figure {
  width: 64px;
  height: 64px;
  overflow: hidden;
  margin: 0px auto;
  padding: 0px;
  background: #3e3f41;
  border-radius: 4px;
  line-height: 64px;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
}
.dashboard-boxes div figure img {
  width: 50%;
}
.dashboard-boxes div h3 {
  transition: all 0.3s ease 0s;
  font-size: 1.4rem;
  padding: 0px;
  font-weight: 400;
  margin: 0px;
  color: #fff;
  padding: 0px 24px;
  line-height: 64px;
  position: relative;
  z-index: 100;
  text-align: left;
}
.dashboard-boxes div:hover h3 {
  transform: translateX(10px);
}

.dashboard-boxes div:hover figure img,
.dashboard-boxes div:focus figure img {
  animation-delay: 0s, 0.3s;
  animation-direction: normal, alternate;
  animation-duration: 0.1s, 0.18s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1, infinite;
  animation-name: hvr-icon-hang-sink, hvr-icon-hang;
  animation-timing-function: ease-out, ease-in-out;
}

@keyframes hvr-icon-hang-sink {
  100% {
    transform: translateY(6px);
  }
}

@keyframes hvr-icon-hang {
  0% {
    transform: translateY(6px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(6px);
  }
}
.dashboard-boxes div:before {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 100% 50% 0;
  transition-duration: 0.4s;
  transition-property: transform;
  transition-timing-function: ease-out;
  z-index: -1;
}

.dashboard-boxes div:hover::before,
.dashboard-boxes div:focus::before {
  transform: scaleX(1);
  z-index: 1;
}

.dashboard-boxes div:nth-of-type(2n + 1) {
  animation: movefromleft;
}
.dashboard-boxes div:nth-of-type(2n + 2) {
  animation: movefromright;
}
.dashboard-boxes div:nth-of-type(1) {
  animation-duration: 0.5s;
  background: #00adf6;
}
.dashboard-boxes div:nth-of-type(2) {
  animation-duration: 0.55s;
  background: #e9672b;
}
.dashboard-boxes div:nth-of-type(3) {
  animation-duration: 0.6s;
  background: #b1cb35;
}
.dashboard-boxes div:nth-of-type(4) {
  animation-duration: 0.65s;
  background: #aa73d9;
}
.dashboard-boxes div:nth-of-type(5) {
  animation-duration: 0.7s;
  background: #f05c43;
}
.dashboard-boxes div:nth-of-type(6) {
  animation-duration: 0.75s;
  background: #eb4b89;
}
.dashboard-boxes div:nth-of-type(7) {
  animation-duration: 0.8s;
  background: #fea63e;
}
.dashboard-boxes div:nth-of-type(8) {
  animation-duration: 0.85s;
  background: #179d54;
}
.dashboard-boxes div:nth-of-type(9) {
  animation-duration: 0.9s;
  background: #36bb9c;
}
.dashboard-boxes div:nth-of-type(10) {
  animation-duration: 0.95s;
  background: #7fa8c6;
}
.dashboard-boxes div:nth-of-type(11) {
  animation-duration: 1s;
  background: #0e809b;
}
.dashboard-boxes div:nth-of-type(12) {
  animation-duration: 1.05s;
  background: #01766d;
}
.dashboard-boxes div:nth-of-type(13) {
  animation-duration: 1.1s;
  background: #ff7260;
}
.dashboard-boxes div:nth-of-type(14) {
  animation-duration: 1.15s;
  background: #7685ac;
}
.dashboard-boxes div:nth-of-type(15) {
  animation-duration: 1.2s;
  background: #73b66b;
}
.dashboard-boxes div:nth-of-type(16) {
  animation-duration: 1.25s;
  background: #fdb632;
}
.dashboard-boxes div:nth-of-type(17) {
  animation-duration: 1.3s;
  background: #00adf6;
}
.dashboard-boxes div:nth-of-type(18) {
  animation-duration: 1.35s;
  background: #eb4b89;
}
.dashboard-boxes div:nth-of-type(19) {
  animation-duration: 1.4s;
  background: #2ecd71;
}
.dashboard-boxes div:nth-of-type(20) {
  animation-duration: 1.45s;
  background: #794472;
}
.dashboard-boxes div:nth-of-type(21) {
  animation-duration: 1.5s;
  background: #ff4c65;
}
@keyframes movefromleft {
  0% {
    opacity: 0.8;
    transform: translate(-700px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes movefromright {
  0% {
    opacity: 0.8;
    transform: translate(700px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/* Quotation Classes */
.quotation-btn button {
  padding: 0px;
  margin: 0px 8px;
  border-radius: 0px;
}
.quotation-btn button i {
  background: #f18247;
  width: 56px;
  height: 56px;
  display: block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}
.quotation-btn button i svg,
.quotation-btn button i path {
  fill: #fff;
}

.quotation-btn h5 span {
  font-size: 0.9rem;
  margin-top: -1px;
  border-radius: 0px 0px 4px 4px;
}
.quotation-btn h5 i {
  font-size: 10px;
}

.quotation-create,
.quotation-info,
.quotation-btn,
.quotation-add-items {
  animation: fadeIn;
  animation-duration: 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.quotation-result-item h2 {
  font-size: 1.2em;
  font-weight: 600;
}
.quotation-result-item h3 {
  font-size: 1.2em;
  font-weight: 700;
}

.quotation-result-item .result-amenities > span.badge {
  background: none !important;
  padding: 4px !important;
  margin: 0px 4px 0px 0px !important;
}

.quotation-result-item .result-amenities > span.badge img {
  width: 16px !important;
  height: 16px !important;
  opacity: 0.7;
}

.quotation-results-loading h2,
.quotation-results-loading h3,
.quotation-results-loading small {
  background: rgba(0, 0, 0, 0.05);
  border: 0px none;
  background: linear-gradient(273deg, #f1f1f1 36%, #ffffff 48%, #f1f1f1 36%);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  min-width: 150px;
}

.quotation-sort .btn {
  font-size: 0.9rem !important;
  line-height: 0.9rem !important;
  background: none !important;
  padding-right: 6px !important;
}
.quotation-sort .btn.active {
  color: rgb(241, 130, 71) !important;
}
.quotation-sort .btn:hover {
  color: rgb(241, 130, 71) !important;
}

.quotation-filters-show {
  display: block;
}
.quotation-filters-hide {
  display: none;
}
.quotation-filters .filter-area {
  box-shadow: none !important;
  border-left: 0px none !important;
  border-bottom: 0px none !important;
}
.custom-file-label.ar-AE::after {
  content: "تصفح" !important;
}
.quotation-email-form textarea {
  height: 124px;
}
.quotation-search-results .ribbons {
  position: relative;
  left: 0px;
  top: 0px;
  padding: 2px 4px;
  font-size: 0.6rem;
  border-radius: 4px;
  margin: -6px 0px -6px 8px;
  display: inline-block;
  background: #17a2b8;
}
.quotation .search-results-air .result-item ul li .img-fluid {
  max-width: 60px;
}

.page-link:hover.btn-outline-primary {
  z-index: 2;
  color: #fff !important;
  text-decoration: none;
  background-color: #f18247;
  border-color: #f18247;
}
.btn-remove-room {
  background-color: #fff !important;
}

.popup-toggle:hover ul {
  display: block !important;
}

.loginbox-static {
  position: relative;
  top: 0px;
  margin: 0px auto;
  width: 500px;
}
.loginbox-static::before {
  display: none;
}

.details-photoslider-transfers img {
  object-fit: contain;
}

.search-widget .dropdown-item {
  white-space: normal;
}

/* Rooms Section Changes */
.group-rooms li .img-fluid {
  width: 100%;
  object-fit: cover;
  height: 100%;
  max-height: 162px;
  min-height: 162px;
}
.rooms-show-moredetails-btn {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px auto;
  background: rgba(0, 0, 0, 0.7);
  border: solid 1px rgba(0, 0, 0, 1);
}
.group-rooms li {
  border-bottom: solid 1px #dee2e6;
}
.group-rooms li:last-child {
  border-bottom: none 0px #dee2e6;
}
.group-rooms:last-child li {
  border-bottom: none 0px #dee2e6;
}
.group-rooms:last-child li.group-rooms-radiomode {
  border-bottom: none 0px #dee2e6 !important;
}

/* Day view Classes */
.dayview > ul.dayview-days {
  position: relative;
}

.dayview ul.dayview-days li.dayview-day:before {
  content: "";
  background: #f18247;
  position: absolute;
  left: 13px;
  top: 0px;
  width: 2px;
  height: 100%;
  z-index: -1;
}

.dayview ul.dayview-days li.dayview-day h4 {
  padding: 0px;
  margin: 0px;
  background: #f18247;
  width: 28px;
  height: 28px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1em;
}
.dayview ul.dayview-days li.dayview-day h5 {
  padding: 0px;
  margin: 0px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}

.dayview-items {
  padding: 0px 0px 0px 38px;
  margin: 8px 0px 8px 0px;
}

.dayview-items > div.dayview-item {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dayview-items > div.dayview-item:last-child {
  border-bottom: 0px none !important;
}

.dayview ul.dayview-days li.dayview-day.dayview-day-total h4 {
  width: 8px;
  height: 8px;
  margin-left: 10px;
  margin-top: 16px;
}

.dayview .dayview-day-total {
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.dayview .dayview-day-total-price div {
  border-radius: 8px 8px 0px 0px;
  padding: 6px 16px;
  position: relative;
  margin-bottom: -1px;
}

.quotation-add-items .form-group.description {
  margin-bottom: 0px;
}
.quotation-add-items .form-group.description textarea {
  height: 88px;
}

.quotation-list-usericon {
  background: #7fa8c6;
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  white-space: nowrap;
  font-size: 28px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  letter-spacing: 100px;
  padding-left: 15px;
  margin: 0px 16px 0px 0px;
}

/* Agent Dashboard */
.agent-dashboard {
  background: #f9fbfc;
}
.agent-dashboard-menu {
  background: #fff;
  margin: 0px 0px -24px 0px;
}
.agent-dashboard-menu li figure {
  margin: 0px 8px 0px 0px;
  padding: 0px;
  display: inline-block;
}
.agent-dashboard-menu li figure img {
  margin: 0px;
  padding: 0px;
  width: 16px;
  height: 16px;
  filter: brightness(0.5) invert(1);
}
.agent-dashboard-countbox {
  background: linear-gradient(
    135deg,
    rgba(235, 72, 134, 1) 0%,
    rgba(184, 85, 164, 1) 100%
  );
  border-radius: 8px;
  text-align: center;
  padding: 16px;
  color: #fff;
}
.agent-dashboard-countbox-1 {
  background: linear-gradient(
    135deg,
    rgba(235, 72, 134, 1) 0%,
    rgba(184, 85, 164, 1) 100%
  );
}
.agent-dashboard-countbox-2 {
  background: linear-gradient(
    135deg,
    rgba(70, 197, 241, 1) 0%,
    rgba(101, 146, 218, 1) 100%
  );
}
.agent-dashboard-countbox-3 {
  background: linear-gradient(
    135deg,
    rgba(255, 185, 45, 1) 0%,
    rgba(245, 128, 90, 1) 100%
  );
}
.agent-dashboard-countbox-4 {
  background: linear-gradient(
    135deg,
    rgba(135, 94, 192, 1) 0%,
    rgba(84, 70, 186, 1) 100%
  );
}
.agent-dashboard-countbox h2 {
  font-size: 3rem;
  font-weight: 600;
}
.agent-dashboard-countbox h5 {
  font-size: 1.1rem;
}
.agent-dashboard-chart {
  background: #fff;
  border-radius: 4px;
  min-height: 300px;
}

/* Splash Preloader */
.preloader {
  background-color: #fff;
}
.lds-ellipsis {
  margin: 0 auto;
  position: relative;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 64px;
  text-align: center;
  z-index: 9999;
}
.lds-ellipsis span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f18247;
  -webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}
.lds-ellipsis span:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}
.lds-ellipsis span:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
}
@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.or-sprt {
  margin: 16px 0px;
  display: block;
  position: relative;
}
.or-sprt b {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-block;
  background: #f8f9fa;
  line-height: 42px;
  border: solid 1px #dee2e6;
}

.or-sprt::after {
  content: "";
  background: #dee2e6;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 22px;
  z-index: -1;
}

@media print {
  .printcontent {
    margin: 15px;
  }
}

@media (max-width: 575px) {
  .dashboard-boxes div {
    width: 96%;
    margin: 8px 2%;
  }
  header .col-9 > button {
    display: none;
  }
  header .navbar > ul > li:first-child > button,
  header .navbar > ul > li:last-child > a {
    font-size: 0px;
    padding: 5px !important;
    margin: 0px !important;
    border: solid 1px #dee2e6;
  }
  header .navbar > ul > li:first-child > button svg,
  header .navbar > ul > li:last-child > a svg {
    padding: 0px !important;
    margin: 0px !important;
  }
  header .login-menu {
    right: 0px !important;
  }
  .call-center-container {
    display: none;
  }
  .quotation-btn button i {
    width: 42px;
    height: 42px;
    margin: 0px auto;
  }
  .quotation-btn button i svg {
    width: 18px;
    height: 18px;
  }
  .quotation .f30 {
    font-size: 1.1rem;
  }
  .quotation .title-bg .btn {
    margin-top: -2px;
  }
  .quotation .title-bg svg {
    width: 20px;
    height: 20px;
  }

  .quotation-list-item .col-lg-6 {
    flex-direction: column;
    text-align: center;
  }
  .quotation-list-item .col-lg-6 small {
    display: block;
    margin: 0px !important;
  }
  .quotation-list-item .col-lg-6 span {
    display: block;
    margin: 0px !important;
  }
  .quotation-list-item .col-lg-6 small span {
    display: block;
    margin: 0px !important;
  }
  .quotation-list-item .col-lg-6 span.quotation-list-usericon {
    margin: 0px 0px 8px 0px !important;
  }
  .quotation-list-item-sprt {
    display: none;
  }

  .quotation-details-item .quotation-details-item-col {
    margin: 0px 0px 12px 0px;
    flex-direction: column !important;
    text-align: center !important;
    align-items: center !important;
  }

  .quotation-details-item .quotation-details-item-col-price {
    margin: 0px;
    flex-direction: unset !important;
    justify-content: center !important;
  }
  .quotation-details-item .quotation-details-item-col-price small {
    text-align: center;
    margin-top: 0px !important;
  }
  .quotation-details-item .quotation-details-item-col .d-flex {
    flex-direction: column !important;
    text-align: center !important;
    align-items: center !important;
  }
  .quotation-details-item > div.row div.d-flex .quotation-details-item-icon {
    margin: 0px 0px 8px 0px;
  }
  .quotation-details-item > div.row div.d-flex .quotation-details-item-title {
    margin: 0px !important;
  }
  .quotation-details-item > div.row div.quotation-details-item-col .stops-cont {
    width: 50%;
  }
  .quotation-info h6 {
    line-height: 20px;
    font-size: 0.8rem;
  }
  .quotation-action-buttons button {
    margin-bottom: 12px;
  }

  .quotation-results-total h6 {
    font-size: 0.8rem;
    line-height: 20px;
  }
  .quotation-results-total .btn {
    align-self: center;
    height: 34px;
  }
  .quotation-sort {
    display: none !important;
  }
  .quotation-result-item > .row > div {
    text-align: center !important;
    justify-content: center !important;
  }
  .quotation-result-item > .row > div small {
    margin-right: 0px !important;
  }
  .quotation-result-item > .row > div.col-lg-2 {
    margin-top: 12px;
  }
  .quotation-result-item > .row > div.col-lg-2 h3 {
    margin: 8px 0px 2px 0px;
  }
  .quotation-result-item > .row > div.col-lg-2 p {
    margin: 0px !important;
  }

  /* Air Responsive */
  .search-results-air .bg-light {
    padding: 12px 0px 8px 0px;
  }
  .search-results-air ul li .row > div {
    width: auto !important;
  }
  .search-results-air ul li .row > div:first-child {
    width: 100% !important;
    flex-direction: inherit !important;
    justify-content: inherit !important;
    align-items: center !important;
    margin-bottom: 12px;
  }
  .search-results-air ul li .row > div:first-child span {
    margin: 0px 0px 0px 8px !important;
  }
  .search-results-air .bg-light {
    flex-direction: inherit !important;
  }
  .search-results-air .bg-light button.w-100 {
    display: none !important;
  }
  .search-results-air .bg-light h2 {
    margin: 0px 16px 0px 0px;
  }

  .quick-book ul li {
    justify-content: center !important;
  }
  .quick-book ul li select {
    width: 50%;
  }
  .quick-book ul li button.btn {
    margin-top: 4px !important;
    margin-bottom: 12px !important;
  }

  .quotation-add-items .BE-Search-Radio {
    margin-bottom: 8px;
  }
  .swap-icon {
    top: 80px !important;
    right: 16px !important;
  }
  #emailHTML > table {
    width: 100% !important;
  }
  #emailHTML table tr td table tr td table tr td {
    width: 100% !important;
    display: block;
  }
  #emailHTML table tr td table tr td table tr td table tr td table tr td img {
    width: 100% !important;
    margin-bottom: 16px;
  }
  .itinerary-landing-page-img {
    margin-bottom: 16px;
  }
  .itinerary-landing-page-img img {
    width: 100% !important;
    height: auto !important;
  }

  /* Login Box Issue Fixed */
  .loginbox-static {
    width: 100%;
  }

  /* Fixed Responsive Issues */
  .search-results-air .result-item .img-fluid {
    max-width: 100px;
  }
  .booking-form > button.btn.btn-primary.mt-4 {
    margin-bottom: 16px;
    width: 100%;
  }
  .cart .cart-item-details {
    padding-left: 28px !important;
  }
  .cart .cart-item-details button.btn.btn-link {
    float: none;
  }
  .cart .cart-item-air .img-fluid {
    width: 110px;
  }
  .cart .cart-item-air ul li > .row div.cart-item-air-trip div {
    min-width: 80px;
    left: 0px !important;
    top: -16px;
    transform: rotate(0deg) !important;
    padding: 4px 0px !important;
  }

  .cart .cart-item-air ul li > .row div {
    width: auto;
  }
  .cart .cart-item-air ul li > .row div.cart-item-air-logo {
    width: 100%;
    margin-bottom: 8px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .dashboard-boxes div {
    width: 96%;
    margin: 8px 2%;
  }
  header .col-9 > button {
    display: none;
  }
  header .login-menu {
    right: 0px !important;
  }
  .call-center-container {
    display: none;
  }

  /* Air Responsive */
  .search-results-air .result-item .img-fluid {
    max-width: 100px;
  }
  .search-results-air ul li .row > div {
    width: auto !important;
  }
  .search-results-air .result-item .row.no-gutters .col-lg-10 {
    width: 80% !important;
  }
  .search-results-air .result-item .row.no-gutters .col-lg-2 {
    width: 20% !important;
  }

  .quotation-info h6 {
    line-height: 22px;
    font-size: 0.9rem;
  }
  .quotation-details-item .quotation-details-item-col.col-lg-4 {
    margin-top: 12px;
  }
  .quotation-details-item .quotation-details-item-col-price small {
    text-align: center;
    margin-top: 0px !important;
  }

  .quotation-results-total h6 {
    font-size: 0.8rem;
    line-height: 20px;
  }
  .quotation-results-total .btn {
    align-self: center;
    height: 34px;
  }
  .quotation-sort {
    display: none !important;
  }
  .quotation-result-item > .row > div {
    text-align: center !important;
    justify-content: center !important;
  }
  .quotation-result-item > .row > div small {
    margin-right: 0px !important;
  }
  .quotation-result-item > .row > div.col-lg-2 {
    margin-top: 12px;
  }
  .quotation-result-item > .row > div.col-lg-2 h3 {
    margin: 8px 0px 2px 0px;
  }
  .itinerary-landing-page-img {
    margin-bottom: 16px;
  }
  .itinerary-landing-page-img img {
    height: auto !important;
  }
  .itinerary-landing-page-items {
    flex-wrap: wrap;
  }
  .itinerary-landing-page-items .quotation-details-item-title {
    margin-left: 0px !important;
  }
  .wishlist {
    display: none !important;
  }
  .result-views {
    float: right;
    margin-top: -16px;
  }
  .result-views li:nth-child(2) {
    display: none !important;
  }
  .result-sorting {
    float: left;
    margin-top: -16px;
    margin-left: -8px;
  }
  .result-sorting b {
    display: none !important;
  }
  .result-filter-area {
    display: none;
  }
  .result-filter-area-show {
    display: block !important;
  }
  .responsive-results-btns {
    display: block !important;
    position: fixed;
    bottom: 8px;
    left: 8px;
    z-index: 10000;
  }
  .mod-search-area {
    display: none;
  }
  .mod-search-area-show {
    display: block;
  }
  .mod-search .search-widget {
    padding-bottom: 0px !important;
  }
  .search-widget .row > div {
    margin-bottom: 16px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .search-widget .row > div .react-bootstrap-daterangepicker-container {
    padding-right: 16px;
  }
  .search-widget
    .row
    > div
    .react-bootstrap-daterangepicker-container
    .row
    > div {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0px;
  }
  .search-results-hotel {
    text-align: center;
  }
  .search-results-hotel .bg-light div {
    width: 50%;
    float: left;
  }
  .search-results-hotel .bg-light > div .btn {
    width: 100%;
  }
  .search-results-hotel .bg-light > .btn.btn-outline-primary {
    width: calc(50% - 16px);
    margin-top: 4px !important;
    margin-left: 16px;
  }
  .booking-form > button.btn.btn-primary.mt-4 {
    margin-bottom: 16px;
    width: 100%;
  }
}

/* Quotation Details Popup Classes */
.quotation-detail-popup .modal-title {
  font-size: 1.25rem !important;
  margin: 0px !important;
}
.quotation-detail-popup .amenities li {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.quotation-detail-popup .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.8;
}
.quotation-detail-popup .swiper-pagination-bullet-active {
  background: rgb(241, 130, 71) !important;
  opacity: 1;
}
.dayview ul.dayview-days li.dayview-day .quotation-detail-popup h4 {
  width: auto;
  height: auto;
  background: none;
  color: #333;
  border-radius: 0px;
  margin-bottom: 8px;
}

/* Itinerary Details Landing Page */
.itinerary-landing-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itinerary-landing-coverImg {
  position: absolute;
  width: 100%;
  height: 272px;
  z-index: -1;
  left: 0px;
  top: 0px;
}
.itinerary-landing-coverImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.itinerary-landing-coverBg {
  background: rgb(67, 76, 91, 0.5);
  height: 272px;
}
.itinerary-landing-coverBg h1 {
  font-size: 2.4rem;
}
.itinerary-landing-coverBg h5 {
  font-size: 1.4rem;
}
.itinerary-landing-coverBg small {
  font-size: 1rem;
}
.yesSmall i {
  height: 18px !important;
  width: 18px !important;
  background-color: green !important;
  float: left !important;
  margin-right: 5px !important;
  margin-top: 4px !important;
}
.groundserviceadditionalservices {
  width: 14px;
  float: left;
  vertical-align: middle !important;
  margin-right: 5px;
}
.flightnotes1 {
  background: #fff3cd;
  padding: 12px 64px 12px 16px;
  margin: 0px;
  float: left;
  width: 100%;
  color: #856404;
  border: solid 1px #ffeeba;
  border-radius: 4px;
  font-size: 15px;
  line-height: 22.5px;
  font-weight: 400;
}
.umrahMutamerRequest {
  font-size: 1.1rem;
}
